<template>
    <div class="page">
        <div class="my-top">
            <div class="insert-picture">
                <div class="pic-content">
                    <van-uploader :after-read="afterRead"/>
                    <div class="info-right">
                        <van-field
                                v-model="username"
                                name="姓名"
                                label="姓名"
                                placeholder="姓名"
                                :rules="[{ required: true, message: '请填姓名' }]"
                        />
                        <van-field
                                v-model="age"
                                name="年龄/性别"
                                label="年龄/性别"
                                placeholder="年龄/性别"
                                :rules="[{ required: true, message: '请填写年龄/性别' }]"
                        />
                    </div>
                </div>
                <div>
                    <van-field
                            v-model="username"
                            name="擅长工种"
                            label="擅长工种"
                            placeholder="擅长工种"
                            :rules="[{ required: true, message: '请填写擅长工种' }]"
                    />
                    <van-field
                            v-model="age"
                            name="工作年限"
                            label="工作年限"
                            placeholder="工作年限"
                            :rules="[{ required: true, message: '请填写工作年限' }]"
                    />
                </div>
            </div>
        </div>
        <div class="basic-info">
            <h3>基本资料</h3>
            <van-field
                    v-model="username"
                    name="个人优势"
                    label="个人优势"
                    placeholder="个人优势"
                    :rules="[{ required: true, message: '请填写个人优势' }]"
            />
            <van-field
                    readonly
                    clickable
                    :value="insurance"
                    name="picker"
                    label="是否有保险"
                    placeholder="点击选择"
                    @click="showPicker = true"
                    right-icon="arrow-down"
            />
            <van-field
                    v-model="username"
                    name="意向区域"
                    label="意向区域"
                    placeholder="意向区域"
                    :rules="[{ required: true, message: '请填写意向区域' }]"
            />
            <van-field
                    v-model="username"
                    name="项目经历"
                    label="项目经历"
                    placeholder="项目经历"
                    :rules="[{ required: true, message: '请填写项目经历' }]"
            />
            <div class="qualification">
                资格证书
                <van-uploader :after-read="afterRead"/>
            </div>

            <div style="">
                <van-button round block type="info" native-type="submit" class="save-btn">保存信息</van-button>
            </div>
        </div>

        <!-- 弹出选择 -->
        <van-popup v-model="showPicker" position="bottom">
            <van-picker
                    show-toolbar
                    :columns="columns"
                    @confirm="onConfirm"
                    @cancel="onCancel"
                    :default-index="0"
                    cancel-button-text="清除已选"
            />
        </van-popup>
    </div>


</template>

<script>

    export default {
        data() {
            return {
                params: {
                    username: undefined,
                    age: undefined,

                },
                insurance: undefined,
                showPicker: false,
                columns: ["是", "否"],
            }
        },
        mounted() {

        },
        methods: {
            afterRead(file) {
                // 此时可以自行将文件上传至服务器
                console.log(file);
            },

            onConfirm(value) {
                this.insurance = value;
                this.showPicker = false;

            },
            onCancel() {
                this.insurance = undefined;
                this.showPicker = false;
            },


        },
        computed: {}
    }

</script>

<style lang="less" scoped>
    .my-top {
        height: 120px;
        width: 100%;
        background: linear-gradient(to right, #1083FC, #08ADFD);
        border-bottom-left-radius: 25px;
        border-bottom-right-radius: 25px;

        .insert-picture {
            max-width: 700px;
            border-radius: 7px;
            width: 94%;
            margin: 0;
            height: 195px;
            background: #FFFFFF;
            position: absolute;
            left: 50%;
            transform: translate(-50%, 30px);

            .pic-content {
                margin-top: 15px;
                margin-left: 10px;
                display: flex;
                justify-content: space-around;

                .info-right {
                    width: 70%;
                }

            }
        }
    }

    .basic-info {
        margin-top: 110px;
        background: #FFFFFF;

        h3 {
            margin-left: 15px;
            padding-top: 15px;
        }

        .qualification {
            color: #646566;
            text-align: left;
            word-wrap: break-word;
            font-size: 14px;
            line-height: 24px;
            margin-left: 15px;
            margin-top: 10px;
        }

        .save-btn {
            position: absolute;
            bottom: 0;
        }
    }


</style>
